<template>
  <ul class="cards products"
      v-if="products">
    <li class="card card--product"
        v-for="p in products">
      <div class="card__wrapper">
        <div :style="{backgroundImage: `url(${p.image.file}?w=640)`}"
             class="card__image">
        </div>

        <div class="card__content">
          <div class="product__info">
            <h3>{{ p.name }}</h3>
            <p v-html="p.description"></p>
          </div>
        </div>
      </div>

      <div class="card__purchase" v-if="p.sold === true">
        <div>Sold</div>
      </div>

      <div class="card__purchase" v-if="p.sold !== true">
        <div>${{ p.price }}</div>
        <form action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_blank">
          <input name="business"
                 type="hidden"
                 value="alita1130@yahoo.com">
          <input name="cmd"
                 type="hidden"
                 value="_xclick">
          <input :value="p.name"
                 name="item_name"
                 type="hidden">
          <input :value="p.price"
                 name="amount"
                 type="hidden">
          <input name="currency_code"
                 type="hidden"
                 value="USD">
          <button name="submit"
                  type="submit">Buy Now
          </button>
        </form>
      </div>
    </li>
  </ul>
</template>

<script>
  export default {
    name: "home__product-list",
    props: [
      "products",
      "cart"
    ],
    methods: {
      add_to_cart(id) {
        this.$emit("add-to-cart", id);
      },
      is_in_cart(id) {
        return this.cart.indexOf(id) > -1;
      }
    }
  };
</script>

<style scoped>

</style>

<template>
  <div id="app">
    <navigation></navigation>
    <router-view :about="about"
                 :categories="categories"
                 :products="products"
                 :tags="tags"></router-view>
    <global-footer></global-footer>
  </div>
</template>

<script>
  import gql from "graphql-tag";

  import Navigation   from "./components/global__navigation.vue";
  import GlobalFooter from "./components/global__footer.vue";

  export default {
    name: "App",
    components: {
      "navigation": Navigation,
      "global-footer": GlobalFooter
    },
    data() {
      return {
        tags: [],
        categories: [],
        products: [],
        about: []
      };
    },
    apollo: {
      $subscribe: {
        categories: {
          query: gql`query {
           categories: getCategories {
              id
              name
              }
            }`,
          result({data}) {
            this.categories = data.categories;
          }
        },
        tags: {
          query: gql`query {
           tags: getTags {
              id
              name
            }
          }`,
          result({data}) {
            this.tags = data.tags;
          }
        },
        products: {
          query: gql`query {
            products: getProducts {
              id
              name
              description
              price
              sold
              image {
                id
                name
                file
              }
              categoryId
              categories {
                id
                name
              }
              tagId
              tags {
                id
                name
              }
              deleted
            }
          }`,
          result({data}) {
            this.products = data.products;
          }
        },
        about: {
          query: gql`query {
            about: getAbout {
            id
            title
            content
            image {
                id
                name
                file
              }
            }
          }`,
          result({data}) {
            this.about = data.about;
          }
        }
      }
    },
    methods: {},
    created() {
    }
  };
</script>

<style lang="scss">

</style>

<template>
  <main id="contact">
    <template v-if="page.title">
      <hero :is-alt="false"
            :title="page.title.rendered"></hero>
      <div class="page page--contact">
        <div class="page__content">
          <section class="cols--2">
            <form :action="`/contact/#gf_${form.id}`"
                  class="form"
                  id="contact-form">
              <h2>{{ form.title }}</h2>
              <div class="input-wrapper"
                   v-for="f in form.fields">
                <label :for="`gf_field_${f.id}`">{{ f.label }}</label>

                <template v-if="f.type === 'text'">
                  <input :id="`gf_field_${f.id}`"
                         type="text"
                         v-model="f.value">
                </template>

                <template v-if="f.type === 'phone'">
                  <input :id="`gf_field_${f.id}`"
                         pattern="^(0*[,.]*[0-9][0-9]*([,.][0-9]+)*|[0-9]?[,.][0-9]*[1-9][0-9]*)$"
                         type="tel"
                         v-model="f.value">
                </template>

                <template v-if="f.type === 'email'">
                  <input :id="`gf_field_${f.id}`"
                         pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                         type="email"
                         v-model="f.value">
                </template>

                <template v-if="f.type === 'textarea'">
                <textarea :id="`gf_field_${f.id}`"
                          v-model="f.value"></textarea>
                </template>
              </div>

              <div class="input-wrapper hidden">
                <label for="gf_field_hp_69">Comments <small>This field is for validation purposes only.
                  Please leave it empty.</small>
                </label>
                <input id="gf_field_hp_69"
                       type="text"
                       v-model="honeypot">
              </div>
            </form>
          </section>

          <section class="cols--2">
            <h2>Retail Locations</h2>
            <ul class="cards">
              <li class="card cols--2"
                  v-for="(l,i) in page.fields.locations">
                <div class="card__wrapper">
                  <div class="card__image">
                    <div :id="`map_${i}`"
                         class="card__image__map"></div>
                    <span v-html="createMap(i, l.address, l.name)"></span>
                  </div>
                  <div class="card__content">
                    <h3>{{ l.name }}</h3>
                    <p>{{ l.address }}</p>
                    <p>
                      <a :href="`https://maps.google.com/maps?&q=${l.address}&amp;z=15`"
                         target="_blank"> View on Google Maps </a>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </template>
  </main>
</template>

<script>
  import Hero from "./global__hero.vue";

  export default {
    name: "contact",
    components: {
      "hero": Hero
    },
    data() {
      return {
        page: {},
        form: {},
        honeypot: ""
      };
    },
    computed: {},
    methods: {
      onFormSubmit() {

      },
      createMap(i, address) {
        if (google.maps.Map && google.maps.Geocoder) {
          const geocoder = new google.maps.Geocoder();
          const geocode = geocoder.geocode({
            address: address
          }, (result) => {
            const coords = {
              lat: result[0].geometry.location.lat(),
              lng: result[0].geometry.location.lng()
            };
            const map = new google.maps.Map(document.getElementById(`map_${i}`), {
              center: coords,
              zoom: 15,
              disableDefaultUI: true
            });

            const pinColor = "#7C5FA6";
            const pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
            const labelOriginHole = new google.maps.Point(12, 15);

            const markerImage = {
              path: pinSVGHole,
              anchor: new google.maps.Point(12, 17),
              fillOpacity: 1,
              fillColor: pinColor,
              strokeWeight: 1,
              strokeColor: "white",
              scale: 2,
              labelOrigin: labelOriginHole
            };

            const marker = new google.maps.Marker({
              position: coords,
              icon: markerImage,
              map: map,
              color: "4F1E84"
            });
          });
        }
        return "";
      }
    },
    created() {
    }
  };
</script>
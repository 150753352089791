<template>
  <div class="product-filter">
    <div class="product-filter__wrapper">
      <div v-if="categories && categories.length">
        <label for="category-select">Category</label>
        <div class="select">
          <select @change="onCategoryChange"
                  id="category-select"
                  v-model="$data._category">
            <option value="0">All</option>
            <option :value="c.id"
                    v-for="c in categories"
                    v-if="c.name !== 'Uncategorized'">{{ c.name }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="tags && tags.length">
        <label for="tag-select">Material</label>
        <div class="select">
          <select @change="onTagChange"
                  id="tag-select"
                  v-model="$data._tag">
            <option value="0">All</option>
            <option :value="t.id"
                    v-for="t in tags">{{ t.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "home__product-filter",
    props: [
      "categories",
      "category",
      "tags",
      "tag"
    ],
    data() {
      return {
        _tag: 0,
        _category: 0
      };
    },
    methods: {
      onCategoryChange(e) {
        this._category = e.target.value;
        this.$emit("update-category", this._category);
      },
      onTagChange(e) {
        this._tag = e.target.value;
        this.$emit("update-tag", this._tag);
      }
    },
    created() {
    }
  };
</script>
<template>
  <main id="cart">
    <hero :title="$route.meta.pageTitle"
          v-if="$route.meta.pageTitle"></hero>
    <div class="cart">
      <ul class="cart-items">
        <li class="item"
            v-for="c in cart">
          <img :src="`${productById(c).image.file}?w=128`">
          <h3>{{ productById(c).name }}</h3>
          <p>${{ productById(c).price }}</p>
        </li>
      </ul>

      <div class="cart-totals card">
        <div class="card__wrapper">

          <div class="card__content">
            <h3>Cart Subtotals</h3>
          </div>
        </div>
      </div>
  </main>
</template>

<script>
  import _    from "lodash";
  import Hero from "./global__hero.vue";

  export default {
    name: "cart",
    components: {
      "hero": Hero
    },
    props: [
      "cart",
      "products"
    ],
    methods: {
      productById(id) {
        return _.find(this.products, {id: id});
      }
    },
    created() {
    }
  };
</script>
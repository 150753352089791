<template>
  <nav class="navigation">
    <div class="navigation__container">
      <div class="navigation__logo">
        <router-link to="/">
          <span>Alita Marie Designs</span>
          <abbr title="Alita Marie Designs">AMD</abbr>
        </router-link>
      </div>

      <div class="navigation__links">
        <router-link to="/about">About</router-link>
        <a href="mailto:alita1130@yahoo.com"
           target="_blank">Contact</a>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: "global__navigation",
    props: ["cart"],
    data() {
      return {
        menuSlug: "primary-navigation",
        menu: [
          {
            id: 1,
            title: "About",
            url: "/about"
          },
          {
            id: 2,
            title: "Contact",
            url: "/contact"
          }
        ]
      };
    },
    created() {
    },
    methods: {}
  };
</script>

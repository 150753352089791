<template>
  <section :class="{alt:isAlt}"
           class="hero"
           id="hero">
    <div class="hero__container">
      <h1 v-bind:class="{alt: isAlt}">{{ sanitizedTitle }}</h1>
    </div>
  </section>
</template>

<script>
  export default {
    name: "global__hero",
    props: [
      "title",
      "isAlt"
    ],
    computed: {
      sanitizedTitle() {
        return this.title.replace(/<\/?[^>]+(>|$)/g, "");
      }
    }
  };
</script>
<template>
  <main id="about">
    <hero :is-alt="false"
          :title="title"></hero>

    <div class="page">
      <section class="page__content"
               v-for="a in about"
               v-if="about">
        <div class="featured-image">
          <img :src="`${a.image.file}?w=960`">
        </div>
        <div class="rendered-text">
          <h3>{{ a.title }}</h3>
          <div v-html="a.content"></div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import Hero from "./global__hero.vue";

  export default {
    name: "About",
    components: {
      "hero": Hero
    },
    props: ["about"],
    data() {
      return {
        title: this.$route.meta.pageTitle
      };
    },
    created() {
    }
  };
</script>
<template>
  <footer class="footer">
    <p>&copy; Alita Marie Designs | Site by <a href="https://killiangrant.com">Killian Grant</a></p>
  </footer>
</template>

<script>
  export default {
    name: "footer"
  };
</script>

<style scoped>

</style>
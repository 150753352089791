<template>
  <main id="home">
    <hero :title="$route.meta.pageTitle"
          v-if="$route.meta.pageTitle"></hero>

    <product-filter :categories="categories"
                    :category="category"
                    :tag="tag"
                    :tags="tags"
                    @update-category="onCategoryUpdate"
                    @update-tag="onTagUpdate"></product-filter>

    <h2 class="hidden">Products</h2>

    <products :cart="cart"
              :products="filteredProducts"
              @add-to-cart="onAddToCart"
              @remove-from-cart="onRemoveFromCart"></products>
  </main>
</template>

<script>
  import Hero              from "./global__hero.vue";
  import HomeProductFilter from "./home__product-filter.vue";
  import Products          from "./home__products.vue";

  export default {
    name: "home",
    components: {
      "hero": Hero,
      "product-filter": HomeProductFilter,
      "products": Products
    },
    props: [
      "tags",
      "categories",
      "products",
      "cart"
    ],
    data() {
      return {
        category: "0",
        tag: "0"
      };
    },
    computed: {
      filteredProducts() {
        return _.filter(this.products, (product) => {
          return (product.categoryId.indexOf(this.category) > -1 && product.tagId.indexOf(this.tag) > -1);
        });
      }
    },
    methods: {
      onTagUpdate(n) {
        this.tag = n;
      },
      onCategoryUpdate(n) {
        this.category = n;
      },
      onAddToCart(id) {
        this.$emit("add-to-cart", id);
      },
      onRemoveFromCart(id) {
        this.$emit("remove-from-cart", id);
      }
    },
    created() {
    }
  };
</script>
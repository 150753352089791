import _                   from "lodash";
import Vue                 from "vue";
import App                 from "./App.vue";
import router              from "./lib/router";
// noinspection ES6UnusedImports
import scss                from "./scss/style.scss";
import {ApolloClient}      from "apollo-client";
import {HttpLink}          from "apollo-link-http";
import {InMemoryCache}     from "apollo-cache-inmemory";
// New Imports
import {split}             from "apollo-link";
import {WebSocketLink}     from "apollo-link-ws";
import {getMainDefinition} from "apollo-utilities";

import VueApollo from "vue-apollo";

const port = process.env.PORT;
const URL = process.env.URL;
const URI = `//${URL}${port !== "80" ? ":" + port : ""}/graphql`;

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: URI
});

// Create the subscription websocket link
const wsLink = new WebSocketLink({
  uri: `wss:${URI}`,
  options: {
    reconnect: true,
    secure: true
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({query}) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" &&
      definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

window._ = _;
Vue.config.productionTip = false;

Vue.use(VueApollo);

new Vue({
  apolloProvider,
  router,
  render: h => h(App)
}).$mount("#app");


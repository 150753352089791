import Vue     from "vue";
import Router  from "vue-router";
import About   from "../components/about.vue";
import Contact from "../components/contact.vue";
import Home    from "../components/home.vue";
import Cart    from "../components/cart.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home

    },
    {
      path: "/about",
      name: "about",
      component: About,
      meta: {
        pageTitle: "About Alita Marie McManis"
      }
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact
    },
    {
      path: "/cart",
      name: "cart",
      component: Cart,
      meta: {
        pageTitle: "Cart"
      }
    }
  ]
});
